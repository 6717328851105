

import { bobaRoutes } from './boba';
import { authRoutes } from './auth';
// import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
// import AuthLayout from '@/components/layouts/AuthLayout.vue'
export const routes = [
  {
    path: '/',
    // component: DefaultLayout,
    children: bobaRoutes,

  },

  {
    path: '/auth',
    // component: AuthLayout,

    children: authRoutes,
  },
  {
    path: '/offline',
    name: 'offline',
    component: () => import('@/views/OfflineView.vue'), 

  },
  {
    path: '/:pathMatch(.*)*',

    children: [{
      path: '',
      component: () => import('@/views/pages/boba/NotFound.vue'),
      meta: {
        layout: 'auth'
      }
    }]

  },
];
