<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.156" height="20" viewBox="0 0 18.156 20"
    class="h-6 w-6 inline-block mb-1">
    <path id="home_FILL1_wght400_GRAD0_opsz48"
      d="M9.7,26.75a1.7,1.7,0,0,1-1.7-1.7V13.984a1.692,1.692,0,0,1,.184-.766,1.615,1.615,0,0,1,.5-.6L16.057,7.09a1.69,1.69,0,0,1,2.043,0l7.376,5.532a1.615,1.615,0,0,1,.5.6,1.692,1.692,0,0,1,.184.766V25.048a1.7,1.7,0,0,1-1.7,1.7H19.348V18.807H14.809V26.75Z"
      transform="translate(-8 -6.75)" fill="#F4DA2D" />
  </svg>
</template>

<script>
export default {
 
 
  

}
</script>

<style></style>