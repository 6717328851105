
import { defineStore } from 'pinia';
import { http } from './https';

export const useSearchStore = defineStore('search', {
    state: () => ({
        games: [],
      
        lang: localStorage.getItem('selectedLocale') ,
        
    }),
    getters: {
        getSearchResults: (state) => state.games,
    },
    actions:{
        async fetchSearchData(query, id){
            return new Promise( (resolve) => {
                let url = `/search/${this.lang}/${query}`;
                if(id !== null && id !== undefined) {
                    url += `/${id}`;
                }
                http.get(url).then(response => {
                    this.games = response.data
                    resolve(response)
                }).catch(error => {
                    console.log(error);
                })
            })
        }
        
    }
});