<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.105" height="19.888" viewBox="0 0 20.105 19.888"  class="h-6 w-6 inline-block mb-1">
    <path id="person_FILL1_wght500_GRAD0_opsz48"
      d="M164.069,269.551a4.615,4.615,0,1,1,3.467-1.345A4.686,4.686,0,0,1,164.069,269.551Zm-7.947,10.271a2.087,2.087,0,0,1-2.1-2.1v-1a3.574,3.574,0,0,1,.612-2.1,3.922,3.922,0,0,1,1.587-1.335,23.226,23.226,0,0,1,4-1.4,16.248,16.248,0,0,1,3.849-.466,15.606,15.606,0,0,1,3.847.482,26.037,26.037,0,0,1,3.974,1.383,3.833,3.833,0,0,1,1.622,1.327,3.579,3.579,0,0,1,.615,2.1v1a2.091,2.091,0,0,1-2.108,2.1Z"
      transform="translate(-154.022 -259.934)" fill="#F4DA2D" />
  </svg>
</template>

<script>
export default {

  props:["routeName"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
}
</script>

<style></style>