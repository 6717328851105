<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.835" height="20" viewBox="0 0 19.835 20"  class="h-6 w-6 inline-block mb-1">
    <path id="category_FILL1_wght500_GRAD0_opsz48"
      d="M111.33,204.207a.815.815,0,0,1-.766-.446.824.824,0,0,1,.019-.88l4.3-6.886a.843.843,0,0,1,1.445,0l4.3,6.886a.832.832,0,0,1,.013.88.81.81,0,0,1-.76.446Zm10.087,11.38a4.471,4.471,0,1,1,3.239-1.309A4.4,4.4,0,0,1,121.416,215.587ZM107,214.955a.838.838,0,0,1-.867-.867v-6.257a.843.843,0,0,1,.867-.867h6.263a.83.83,0,0,1,.616.248.845.845,0,0,1,.245.619v6.257a.838.838,0,0,1-.861.867Z"
      transform="translate(-106.13 -195.587)" fill="#F4DA2D" />
  </svg>
</template>

<script>
export default {

}
</script>

<style></style>