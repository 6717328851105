<template>
  <app-loader v-if="getIsChanged" />

  <component :is="layoutComponent" v-else>
    <!-- This is where your layout-specific content will go -->
  </component>
</template>

<script>
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import AuthLayout from '@/components/layouts/AuthLayout.vue'
import AppLoader from './components/loaders/AppLoader.vue'
import { useLanguageStore } from '@/store/language'
import { mapState } from 'pinia'
export default {
  name: 'App',
  inject: ['isRtlLayout'],
  components: {
    AppLoader
  },
  mounted () {
    // Retrieve the selected locale from local storage when the component is mounted
    this.$i18n.locale = localStorage.getItem('selectedLocale')
  },
  watch: {
    getIsChanged (newValue, oldValue) {
      console.log(`Message changed from ${newValue} to ${oldValue}`)
    }
  },
  computed: {
    ...mapState(useLanguageStore, ['getIsChanged']),
    layoutComponent () {
      // Determine which layout component to use based on the route's meta information
      const routeMetaLayout = this.$route.meta.layout

      // Use the AuthLayout if specified in the route meta
      if (routeMetaLayout === 'auth') {
        return AuthLayout
      }
      // Default to the DefaultLayout if no layout is specified or if none of the conditions match
      return DefaultLayout
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400&display=swap');

*:focus {
  outline: none !important;
}
</style>
