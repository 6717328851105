<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20.156" height="22" viewBox="0 0 20.156 22"
    class="h-6 w-6 inline-block mb-1">
    <path id="home_FILL1_wght400_GRAD0_opsz48"
      d="M24.454,27.75H19.348a1,1,0,0,1-1-1V19.807H15.809V26.75a1,1,0,0,1-1,1H9.7a2.7,2.7,0,0,1-2.7-2.7V13.984a2.691,2.691,0,0,1,.29-1.213,2.613,2.613,0,0,1,.8-.952L15.457,6.29a2.69,2.69,0,0,1,3.243,0l7.37,5.528a2.613,2.613,0,0,1,.8.953,2.691,2.691,0,0,1,.29,1.213V25.048a2.7,2.7,0,0,1-2.7,2.7Zm-4.106-2h4.106a.7.7,0,0,0,.7-.7V13.984a.69.69,0,0,0-.079-.319.617.617,0,0,0-.19-.234l-.012-.009L17.5,7.89a.69.69,0,0,0-.843,0L9.281,13.422l-.012.009a.617.617,0,0,0-.19.234A.691.691,0,0,0,9,13.984V25.048a.7.7,0,0,0,.7.7h4.106V18.807a1,1,0,0,1,1-1h4.539a1,1,0,0,1,1,1Z"
      transform="translate(-7 -5.75)" fill="#fff" />
  </svg>
</template>

<script>
export default {

}
</script>

<style></style>