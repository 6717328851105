<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19.835" height="20" viewBox="0 0 19.835 20"
    class="h-6 w-6 inline-block mb-1">
    <path id="category_FILL0_wght500_GRAD0_opsz48"
      d="M111.33,204.207a.84.84,0,0,1-.77-.443.785.785,0,0,1,.022-.883l4.3-6.886a.8.8,0,0,1,.72-.408.812.812,0,0,1,.725.408l4.3,6.886a.794.794,0,0,1,.018.883.834.834,0,0,1-.765.443Zm10.084,11.38a4.473,4.473,0,1,1,3.24-1.309A4.394,4.394,0,0,1,121.414,215.587ZM107,214.955a.848.848,0,0,1-.867-.867v-6.257a.843.843,0,0,1,.248-.615.834.834,0,0,1,.619-.252h6.263a.827.827,0,0,1,.613.252.843.843,0,0,1,.248.615v6.257a.848.848,0,0,1-.861.867Zm14.422-1.1a2.795,2.795,0,1,0-2.008-.816A2.719,2.719,0,0,0,121.419,213.859Zm-13.561-.632H112.4v-4.535h-4.541Zm5.018-10.748h5.455l-2.728-4.417ZM115.6,202.479ZM112.4,208.692ZM121.416,211.039Z"
      transform="translate(-106.13 -195.587)" :fill="currentRouteName == routeName ? '#F4DA2D' : '#fff'" />
  </svg>
</template>

<script>
export default {

  props:["routeName"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },


}
</script>

<style></style>