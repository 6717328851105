<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="h-6 w-6 inline-block mb-1">
    <path id="explore_FILL0_wght500_GRAD0_opsz48"
      d="M79.672,184.618l5.917-2.823a.766.766,0,0,0,.251-.165.752.752,0,0,0,.165-.256l2.823-5.911a.361.361,0,0,0-.487-.487L82.43,177.8a.752.752,0,0,0-.256.165.765.765,0,0,0-.165.251l-2.823,5.916a.361.361,0,0,0,.487.488Zm4.347-3.848a.967.967,0,1,1,.7-.283A.953.953,0,0,1,84.019,180.77Zm0,9.012A9.686,9.686,0,0,1,80.141,189a10.064,10.064,0,0,1-5.332-5.331,9.947,9.947,0,0,1,0-7.757,10.083,10.083,0,0,1,5.33-5.333,9.908,9.908,0,0,1,7.758,0,10.1,10.1,0,0,1,5.333,5.333,9.908,9.908,0,0,1,0,7.758,10.085,10.085,0,0,1-5.333,5.33A9.672,9.672,0,0,1,84.02,189.782ZM84.019,179.785Zm0,8.319a8.342,8.342,0,1,0-5.872-2.442A8.022,8.022,0,0,0,84.016,188.1Z"
      transform="translate(-74.022 -169.782)" fill="#fff" />
  </svg>
</template>

<script>
export default {
  props: ["currentRoute", "routeName"]
}
</script>

<style></style>