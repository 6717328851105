<template>
  <div id="page-container-auth">
    <div id="content-wrap-auth">

      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
  
<script>

// import NavBar from "../partials/desktop/NavBar.vue";

export default {
  name: "AuthLayout",
  components: {},
  data() {
    return {};
  },
  mounted() { },
 
};
</script>
  
<style></style>
<style scoped>
#page-container-auth {
  position: relative;
  min-height: 100vh;
}

#content-wrap-auth {
  padding-bottom: 6rem;
  /* Footer height */
}

*:focus {
  outline: none;
}
</style>
  