<template>
    <div id="app" class="relative hidden ">
        <!-- Your main app content here -->
        <div v-if="showReloadPrompt"
            class="reload-prompt absolute z-50 top-12 md:left-1/2 bg-[#57214f] rounded-md text-white p-3 mx-3 custom-shadow  animate-jump-in animate-duration-500 animate-delay-100 animate-ease-linear ">
            <p> إصدار جديد متوفر. هل تريد إعادة تحميل الصفحة للتحديث؟</p>
            <button @click="reloadPage" class="py-2 px-6 text-center rounded-md  text-gray-900  bg-[#F4DA2D] my-3">إعادة
                تحميل</button>
        </div>
    </div>
</template>
  
<script>
export default {

    data() {
        return {
            showReloadPrompt: JSON.parse(localStorage.getItem('updateAvailable')) || false
        };

    },
    mounted() {


    },
    methods: {
        reloadPage() {
            localStorage.setItem('updateAvailable', JSON.stringify(false));
            this.showReloadPrompt = false;
            window.location.reload();

        },

        updateLocalData() {
            this.showReloadPrompt = JSON.parse(localStorage.getItem('updateAvailable')) || false;
        }
    },
    watch: {
        showReloadPrompt(newVal) {
            if (newVal) {
                localStorage.setItem('updateAvailable', JSON.stringify(newVal));
            }
        }
    },

    created() {

        window.addEventListener('storage', this.updateLocalData);
    },
    unmounted() {
        window.removeEventListener('storage', this.updateLocalData);
    },

};
</script>
