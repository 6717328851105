<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" class="h-6 w-6 inline-block mb-1">
    <path id="explore_FILL1_wght400_GRAD0_opsz48"
      d="M9.575,18.925l6.025-2.95a.6.6,0,0,0,.35-.35L18.9,9.6a.368.368,0,0,0-.5-.5l-6.025,2.95a.6.6,0,0,0-.35.35l-2.95,6.025a.368.368,0,0,0,.5.5ZM14,15a.982.982,0,1,1,.712-.288A.968.968,0,0,1,14,15Zm0,9a9.676,9.676,0,0,1-3.875-.788,10.075,10.075,0,0,1-5.338-5.337,9.927,9.927,0,0,1,0-7.75,10.075,10.075,0,0,1,5.338-5.338,9.927,9.927,0,0,1,7.75,0,10.075,10.075,0,0,1,5.337,5.338,9.927,9.927,0,0,1,0,7.75,10.075,10.075,0,0,1-5.337,5.337A9.676,9.676,0,0,1,14,24Z"
      transform="translate(-4 -4)" fill="#F4DA2D" />
  </svg>
</template>

<script>
export default {
  props: ["routeName"],
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

}
</script>

<style></style>